
import { BlockchainServices } from '@/services/blockchainServices';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ethers } from 'ethers';
import { _toFixed } from '@/utils';
import TransactionSuccessModal from '../../modals/transactionSuccessModal.vue';
import InfoText from './infoText.vue';
import OperationErrorModal from '../../modals/operationErrorModal.vue';

@Options({
    components: {
        InfoText
    }
})
export default class Buy extends Vue {

    @Prop({
        default: 0
    }) price: number;

    @Prop({
        default: ""
    }) fundContractAddress: string;

    usdBalance: number = 0;
    dollarsAmount: number = null;


    loadingConfirmation: boolean = false;

    notApproved: boolean = true;
    isDisabledButton: boolean = true;

    async created() {        
        var signer = this.getSigner();
        this.usdBalance = await BlockchainServices.getUSDBalance(this.fundContractAddress, signer);
    }

    getSigner() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        var signer = provider.getSigner();
        return signer;
    }

    async buyApprove() {
        this.loadingConfirmation = true;

        var signer = this.getSigner();
        var checkedGetAllowance = await BlockchainServices.checkSufficientAllowance(this.fundContractAddress, this.dollarsAmount, signer);
        
        if(checkedGetAllowance)
        {
            this.loadingConfirmation = false;
            this.isDisabledButton = true;
        }
        else {
            try {
                var approve = await BlockchainServices.approve(this.fundContractAddress, signer);

                if(approve) {
                    this.loadingConfirmation = false;
                    this.alwaysCheckUsdAllowance();
                }
            }
            catch(ex: any){
                this.loadingConfirmation = false;
                this.$opModal.show(OperationErrorModal, {
                message: ex.message
            })
            }
        }
    }
    async buyAction() {
        this.loadingConfirmation = true;

        var signer = this.getSigner();

        try {
            var buyTxhash = await BlockchainServices.buy(this.fundContractAddress, this.dollarsAmount, signer);
            setTimeout(() => {
                this.loadingConfirmation = false;
                this.alwaysCheckUsdAllowance();
                this.$emit('activeSectionSelected', "start");
                this.dollarsAmount = null;
                if(buyTxhash.success == true){ //se un utente rifiuta il buy la chiamata non va in catch quindi va gestita 
                        this.$opModal.show(TransactionSuccessModal, {
                        txhash: buyTxhash.txHash,
                        closeModal: () => {
                            this.$opModal.closeLast();
                        }
                    })
                }else{
                    this.loadingConfirmation = false;
                    this.$opModal.show(OperationErrorModal, {
                        message: buyTxhash.txHash
                    })
                }
                
            }, 1000);
        }
        catch(ex: any) {
            this.loadingConfirmation = false;
            this.$opModal.show(OperationErrorModal, {
                message: ex.message
            })
        }
    }

    get TDTAmountFromDollarsAmount() {
        if(!this.dollarsAmount)
            return null;

        var ris = (this.dollarsAmount * this.price) / (1e18);
        return _toFixed(ris);   
    }


    
    preventInvalidCharacters(event) {
        // Ottieni il tasto premuto
        const key = event.key;

        // Verifica se il tasto è uno dei seguenti: "+", "-", "e", ".","," ecc.
        if (key === "+" || key === "-" || key === "e" || key === ",") {
            event.preventDefault(); // Impedisci l'inserimento del tasto
        }
    }



    @Watch("dollarsAmount")// confronto in tempo reale quello che voglio spendere con quello che ho gia approvato 
    async alwaysCheckUsdAllowance(){ 

        //se ho scritto 0, non ho scritto nulla nell input , o voglio comprare + di quello che ho a portafoglio => disabilito il buy e approve non va
        this.notApproved = true;
        if(this.dollarsAmount == 0 || this.dollarsAmount  == null || this.dollarsAmount == undefined  || this.dollarsAmount > this.usdBalance){ 
            this.isDisabledButton = true;
            return
        }
        
        var signer = this.getSigner();
        var allowanceCurrentSpending = await BlockchainServices.checkCurrentAllowance(this.fundContractAddress, this.dollarsAmount, signer); //quello che ho approvato per questo contratto
        var currentamountInDollar = this.dollarsAmount * (1e6); //quello che voglio spendere e ho messo nel input

        if(currentamountInDollar > allowanceCurrentSpending ){  //se voglio spendere piu di quello che ho approvato disabilito buy e abilito approve
            this.notApproved = true;
            this.isDisabledButton = true;
            
        }else if(allowanceCurrentSpending >= currentamountInDollar || allowanceCurrentSpending == 0){ //se invece quello che voglio spendere è gia stato approvato abilito il buy e disabilito l' approve
            this.notApproved = false;
            this.isDisabledButton = false;
        }
    }
}
