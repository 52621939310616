
import { BlockchainServices } from '@/services/blockchainServices';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ethers } from 'ethers';
import TransactionSuccessModal from '../../modals/transactionSuccessModal.vue';
import InfoText from './infoText.vue';
import OperationErrorModal from '../../modals/operationErrorModal.vue';

@Options({
    components: {
        InfoText
    }
})
export default class Withdraw extends Vue {

    @Prop({
        default: 0
    }) price: number;

    @Prop({
        default: ""
    }) fundContractAddress: string;

    @Prop({
        default: 0
    }) tokenAmount: number;
    localTokenAmountString: string = "";

    @Prop({
        default: false
    }) withdrawApprovedDone: boolean;
    localWithdrawApprovedDone: boolean = false;

    tokenSymbol: string = "";
    balanceTokenString: string = "";
    loadingConfirmation: boolean = false;

    notApproved: boolean = true;
    isDisabledButton: boolean = true;

    async created() {
        this.localTokenAmountString = this.tokenAmount.toString();
        this.localWithdrawApprovedDone = this.withdrawApprovedDone;

        var signer = this.getSigner();
        this.tokenSymbol = await BlockchainServices.getTokenSymbol(this.fundContractAddress, signer);
        this.balanceTokenString = await BlockchainServices.getFundBalance(this.fundContractAddress, this.price, signer);
    }

    getSigner() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        var signer = provider.getSigner();
        return signer;
    }

    checkIfDifferentFromDefault() {
        if(this.localTokenAmountString != this.tokenAmount.toString())
            this.localWithdrawApprovedDone = false;
        else
            this.localWithdrawApprovedDone = true;
    }

    // async checkWithdrawPending() {
    //     this.loadingConfirmation = true;

    //     var signer = this.getSigner();
    //     try {
    //         var pendingRequest = await BlockchainServices.checkPendingWithdrawRequest(this.fundContractAddress, signer);
    //         if (pendingRequest > 0) {
    //             this.tokenAmount = pendingRequest;
    //             this.withdrawApprovedDone = true;
    //         }
    //         else {
    //             this.$emit('activeSectionSelected', 'withdraw');
    //             this.loadingConfirmation = false;
    //         }
    //     }
    //     catch(ex: any) {
    //         this.loadingConfirmation = false;
    //         alert(ex);
    //     }
    // }

    async withdrawRequest() {
        this.loadingConfirmation = true;

        var signer = this.getSigner();
        try {
            var request = await BlockchainServices.withdrawRequest(this.fundContractAddress, this.localTokenAmount, signer);
            this.localWithdrawApprovedDone = true;
            this.loadingConfirmation = false;
        }
        catch(ex: any) {
            this.loadingConfirmation = false;
            this.$opModal.show(OperationErrorModal, {
                message: ex.message
            })
        }
    }
    async withdrawAction() {
        this.loadingConfirmation = true;

        var signer = this.getSigner();
        try {
            var claimTxhash = await BlockchainServices.claim(this.fundContractAddress, signer);

            setTimeout(() => {
                this.loadingConfirmation = false;
                this.$emit('activeSectionSelected', 'start');
                this.$opModal.show(TransactionSuccessModal, {
                    txhash: claimTxhash,
                    closeModal: () => {
                        this.$opModal.closeLast();
                    }
                })
            }, 1000);
        }
        catch(ex: any) {
            this.loadingConfirmation = false;
            this.$opModal.show(OperationErrorModal, {
                message: ex.message
            })
        }
    }

    get localTokenAmount() {
        return parseFloat(this.localTokenAmountString);
    }
    get balanceToken() {
        return parseFloat(this.balanceTokenString);
    }

    get dollarsAmountFromTokenAmount() {
        if(!this.localTokenAmount)
            return null;

        return ((this.localTokenAmount * 1e18) * this.price)/(1e6);
    }

    @Watch("localTokenAmountString")// confronto in tempo reale quello che voglio prelevare con quello che ho gia approvato al prelievo 
    async alwaysCheckWithdrawPending(){
        var ammountToWithdraw = (Number(this.localTokenAmountString) * (1e18));
        //se ho scritto 0, non ho scritto nulla nell input , o voglio comprare + di quello che ho a portafoglio => disabilito il buy e approve non va
        this.notApproved = true;
        if(Number(this.localTokenAmountString) == 0 || Number(this.localTokenAmountString)  == null || Number(this.localTokenAmountString) == undefined  || Number(this.localTokenAmountString) > ammountToWithdraw){ 
            this.isDisabledButton = true;
            return
        }
        var signer = this.getSigner();
        var requestAmmonutAlreadyApproved = await BlockchainServices.checkPendingWithdrawRequest(this.fundContractAddress, signer);
        console.log("requestAmmonutAlreadyApproved", requestAmmonutAlreadyApproved)

        if(ammountToWithdraw > requestAmmonutAlreadyApproved ) { //se voglio prelevare piu di quello che ho approvato disabilito claim e abilito request
            this.notApproved = true;
            this.isDisabledButton = true;
        }else if(requestAmmonutAlreadyApproved >= ammountToWithdraw  || requestAmmonutAlreadyApproved == 0){ //se invece quello che voglio prelevare è gia stato approvato abilito il claim e disabilito request
            this.notApproved = false;
            this.isDisabledButton = false;
        }

    }

}
