
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class InfoText extends Vue {

    created() {
        
    }

}
