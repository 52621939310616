
import { BlockchainServices } from '@/services/blockchainServices';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ethers } from 'ethers';
import OperationSucceedModal from '../../modals/operationSucceedModal.vue';

@Options({})
export default class MetamaskImportToken extends Vue {

    @Prop({
        default: ""
    }) fundContractAddress: string;

    created() {
    }

    async importToken() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        var signer = provider.getSigner();

        try {
            var ris = await BlockchainServices.importCustomToken(this.fundContractAddress, signer);
            this.$opModal.show(OperationSucceedModal, {});
        }
        catch(ex: any) {
            console.log(ex);
        }
    }

}
