
import { Options, Vue } from 'vue-class-component';
import { MerlinFundPriceClient } from '@/services/Services';
import { BlockchainServices } from '@/services/blockchainServices';
import { ethers } from 'ethers';
import { connectMetamask } from '@/utils';
import BugReportCTA from '../components/bugReportCTA.vue';
import FundChart from '../components/fundChart.vue';
import TokenWeight from '../components/tokenWeight.vue';
import TokenManagement from '../components/tokenManagement/tokenManagement.vue';
import ConnectWalletModal from '../modals/connectWalletModal.vue';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
        BugReportCTA,
        FundChart,
        TokenWeight,
        TokenManagement
    }
})
export default class FundDetail extends Vue {

    tabActive: number = 1;
    loaded: boolean = false;
    loadingGraph: boolean = false;
    
    fundAddress: string = "";
    filter: OM.FundFilterVM = new OM.FundFilterVM();
    fundChartData: OM.FundPriceDetailsVm[] = []; 

    fundDetail: VM.Fund = new VM.Fund();
    
    created() {
        this.fundAddress = this.$route.params.fundAddress.toString();
        this.filter.fundContractAddress = this.fundAddress;
        this.filter.activeFilter = 2;
        
        this.init();
    }

    mounted() {
        document.addEventListener("scroll", (event) => {
            var box = document.getElementById("sideBarFixed");
            var width = box.clientWidth;

            if(window.scrollY > 100) {
                box.style.width = width.toString() + "px";
                box.style.position = "fixed";
                box.style.top = "30px";
            }
            else {
                box.style.width = "100%";
                box.style.position = "relative";
                box.style.top = "0px";
            }
        });

    }

    async init() {
        var checkIfConnected = await BlockchainServices.connectedMetamask();

        if(checkIfConnected) {
            store.state.walletAddress = window.ethereum.selectedAddress;
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            var signer = provider.getSigner();
            this.getFundInfos(signer);
        }
        else {
            this.$opModal.show(ConnectWalletModal, {
                connectToMetamask: async () => {
                    var ris = connectMetamask();
                    this.$opModal.closeLast();
                },
                closeModal: () => {
                    this.$opModal.closeLast();
                    this.$router.replace("/");
                }
            })
        }
    }

    async getFundInfos(provider: ethers.providers.JsonRpcSigner) {

        this.fundChartData = [];

        this.loadingGraph = true;
        MerlinFundPriceClient.getAllPriceByFundFrom(this.filter)
        .then(async x => {
            store.state.showSpinner++;            
            
            this.fundChartData = x;
            
            this.fundDetail = new VM.Fund();
            this.fundDetail.contractAddress = this.fundAddress;
            
            this.fundDetail.imageUrl = await BlockchainServices.getSymbol(this.fundAddress, provider);
            this.fundDetail.title = await BlockchainServices.getName(this.fundAddress, provider);
            this.fundDetail.price = await BlockchainServices.getFundPriceInDollar(this.fundAddress, provider);
            this.fundDetail.allTimeHigherPrice = await MerlinFundPriceClient.getAllTimeHigher(this.fundAddress, false);
            this.fundDetail.marketCap = await BlockchainServices.getMarketCap(this.fundAddress, this.fundDetail.price, provider);
            
            var allPricesSteps = await MerlinFundPriceClient.getFundPricesStep(this.fundAddress, false);
            this.fundDetail.lastWeekPercentage = this.getPercentageVaration(this.fundDetail.price, allPricesSteps.week);
            this.fundDetail.yesterdayPercentage = this.getPercentageVaration(this.fundDetail.price, allPricesSteps.day);
            this.fundDetail.lastHourPercentage = this.getPercentageVaration(this.fundDetail.price, allPricesSteps.hour);
            this.fundDetail.lastWeekPercentageString = (this.fundDetail.lastWeekPercentage).toFixed(2);
            this.fundDetail.yesterdayPercentageString = (this.fundDetail.yesterdayPercentage).toFixed(2);
            this.fundDetail.lastHourPercentageString = (this.fundDetail.lastHourPercentage).toFixed(2);
            
            var tokensList = await BlockchainServices.getTokensInFundWithPercentages(this.fundAddress, provider);
            for(var i = 0; i < tokensList.length; i++) {
                var token = tokensList[i];
                var imageUrl = await BlockchainServices.getImageSymbolUrl(token.contrat, provider);
                
                var tokenVM = new VM.TokenInFund();
                tokenVM.contractAddress = token.contrat;
                tokenVM.imageUrl = imageUrl;
                tokenVM.title = token.name;
                tokenVM.percentage = token.percentage;
                this.fundDetail.tokens.push(tokenVM);
            }
            
            this.loaded = true;
            this.loadingGraph = false;
            
            store.state.showSpinner--;
        })

        // this.fundChartData = [];

        // this.loadingGraph = true;
        // this.fundChartData = await MerlinFundPriceClient.getAllPriceByFundFrom(this.filter);
        // store.state.showSpinner++;            
            
        // this.fundDetail = new VM.Fund();
        // this.fundDetail.contractAddress = this.fundAddress;
        
        // this.fundDetail.imageUrl = await BlockchainServices.getSymbol(this.fundAddress, provider);
        // this.fundDetail.title = await BlockchainServices.getName(this.fundAddress, provider);
        // this.fundDetail.price = await BlockchainServices.getFundPriceInDollar(this.fundAddress, provider);
        // this.fundDetail.allTimeHigherPrice = await MerlinFundPriceClient.getAllTimeHigher(this.fundAddress, false);
        // this.fundDetail.marketCap = await BlockchainServices.getMarketCap(this.fundAddress, this.fundDetail.price, provider);
        
        // var allPricesSteps = await MerlinFundPriceClient.getFundPricesStep(this.fundAddress, false);
        // this.fundDetail.lastWeekPercentage = this.getPercentageVaration(this.fundDetail.price, allPricesSteps.week);
        // this.fundDetail.yesterdayPercentage = this.getPercentageVaration(this.fundDetail.price, allPricesSteps.day);
        // this.fundDetail.lastHourPercentage = this.getPercentageVaration(this.fundDetail.price, allPricesSteps.hour);
        // this.fundDetail.lastWeekPercentageString = (this.fundDetail.lastWeekPercentage).toFixed(2);
        // this.fundDetail.yesterdayPercentageString = (this.fundDetail.yesterdayPercentage).toFixed(2);
        // this.fundDetail.lastHourPercentageString = (this.fundDetail.lastHourPercentage).toFixed(2);
        
        // var tokensList = await BlockchainServices.getTokensInFundWithPercentages(this.fundAddress, provider);
        // for(var i = 0; i < tokensList.length; i++) {
        //     var token = tokensList[i];
        //     var imageUrl = await BlockchainServices.getImageSymbolUrl(token.contrat, provider);
            
        //     var tokenVM = new VM.TokenInFund();
        //     tokenVM.contractAddress = token.contrat;
        //     tokenVM.imageUrl = imageUrl;
        //     tokenVM.title = token.name;
        //     tokenVM.percentage = token.percentage;
        //     this.fundDetail.tokens.push(tokenVM);
        // }
        
        // this.loaded = true;
        // this.loadingGraph = false;
            
        // store.state.showSpinner--;
    }

    getPercentageVaration(actualPrice: number, benchmarkPrice: number)
    {
        try
        {
            var variation: number = actualPrice - benchmarkPrice;
            var variationPercentage = (variation/benchmarkPrice)*100;
            return variationPercentage;
        }
        catch(ex)
        {
            console.log("Error in getPercentageVaration", ex);
        }
    }

    changeFilter(value: any) {
        this.filter.activeFilter = value;
        this.loadingGraph = true;

        MerlinFundPriceClient.getAllPriceByFundFrom(this.filter, false)
        .then(x => {
            this.fundChartData = x;
            this.loadingGraph = false;
        })
    }

}
