
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class LoadingBar extends Vue {

    @Prop({
        default: 0
    }) value: number;

    created() {
    }

    mounted() {
        var content = <HTMLElement>this.$refs.loadingBarValue;

        if(content.offsetTop < window.innerHeight)
            content.style.width = this.value.toString() + "%";
        else {
            document.addEventListener("scroll", (event) => {
                if(window.scrollY - (content.offsetTop - window.innerHeight) > 10)
                    content.style.width = this.value.toString() + "%";
            })
        }
    }

}
