
import { BlockchainServices } from '@/services/blockchainServices';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ethers } from 'ethers';
import MetamaskImportToken from './metamaskImportToken.vue';
import InfoText from './infoText.vue';

@Options({
    components: {
        MetamaskImportToken,
        InfoText
    }
})
export default class Start extends Vue {

    @Prop({
        default: 0
    }) price: number;

    @Prop({
        default: ""
    }) fundContractAddress: string;

    showCopiedText: boolean = false;

    balanceString: string = "";
    loadingConfirmation: boolean = false;

    async created() {       
        var signer = this.getSigner();
        this.balanceString = await BlockchainServices.getFundBalance(this.fundContractAddress, this.price, signer);
    }

    getSigner() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        var signer = provider.getSigner();
        return signer;
    }

    copyAddress() {
        navigator.clipboard.writeText(this.fundContractAddress);

        this.showCopiedText = true;
        setTimeout(() => {
            this.showCopiedText = false;
        }, 1000);
    }

    async checkWithdrawPending() {
        this.loadingConfirmation = true;

        var signer = this.getSigner();
        try {
            var pendingRequest = await BlockchainServices.checkPendingWithdrawRequest(this.fundContractAddress, signer);
            if (pendingRequest > 0) {
                this.$emit('tokenAmountPreselected', (pendingRequest/1e18));
                this.$emit('activeSectionSelected', 'withdraw');
            }
            else {
                this.$emit('activeSectionSelected', 'withdraw');
                this.loadingConfirmation = false;
            }
        }
        catch(ex: any) {
            this.loadingConfirmation = false;
            alert(ex);
        }
    }

    get balance() {
        return parseFloat(this.balanceString);
    }
}
