import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c992ad7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tokenWeight" }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "fs_12 fw_600 blue text-uppercase d-block" }
const _hoisted_5 = { class: "fs_12 fw_600 blue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_bar = _resolveComponent("loading-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "imageContainer",
          style: _normalizeStyle('background-image: url(\'' + _ctx.token.imageUrl + '\');')
        }, null, 4),
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.token.title), 1)
        ])
      ]),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.token.percentage) + "%", 1)
    ]),
    _createVNode(_component_loading_bar, {
      value: _ctx.token.percentage
    }, null, 8, ["value"])
  ]))
}