import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/metamask_icon.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _imports_0,
    class: "pointer",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.importToken()))
  }))
}