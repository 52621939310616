
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Chart from 'chart.js/auto';
import * as OM from '@/Model';
import { createObjectProperty } from '@vue/compiler-core';

@Options({})
export default class FundChart extends Vue {

    @Prop({
        default: []
    }) dataSet: OM.FundPriceDetailsVm[] = [];

    chartData: number[] = [];
    labels: string[] = [];

    created() {
        this.dataSet.forEach(element => {
            this.chartData.push(element.priceInDollar);

            var date = new Date(element.time);
            var hours = date.getHours();
            var stringHours = hours.toString();
            if(hours < 10)
                stringHours = "0" + hours;

            var minutes = date.getMinutes();
            var stringMinutes = minutes.toString();
            if(minutes < 10)
                stringMinutes = "0" + minutes;
            
            var formatted = date.toLocaleDateString("en-US") + " - " + stringHours + ":" + stringMinutes;
            this.labels.push(formatted);
        });
    }

    async mounted() {
        var ctx = <HTMLCanvasElement>document.getElementById("chart");
        var chart = new Chart(ctx, <any> {
            type: 'line',
            data: {
                datasets: [{
                    label: '',
                    data: this.chartData,
                    borderWidth: 1.7,
                    borderColor: "#6CA7FF",
                    backgroundColor: "rgba(5, 21, 62, .084)",
                    tension: 0,
                    pointRadius: 0,
                    pointHoverRadius: 0
                }],
                labels: this.labels,
            },
            plugins: [
                {
                    id: 'corsair',
                    afterInit: (chart) => {
                        chart.corsair = {
                            x: 0,
                            y: 0
                        }
                    },
                    afterEvent: (chart, evt) => {
                        
                        let top = chart.top;
                        let bottom = chart.bottom;
                        let left = chart.left;
                        let right = chart.right;

                        let x = evt.event.x;
                        let y = evt.event.y;

                        if (x < left || x > right || y < top || y > bottom) {
                            chart.corsair = {
                                x,
                                y,
                                draw: false
                            }
                            chart.draw();
                            return;
                        }

                        chart.corsair = {
                            x,
                            y,
                            draw: true
                        }
                        chart.draw();
                    },
                    afterDatasetsDraw: (chart, _, opts) => {
                        let ctx: CanvasRenderingContext2D = chart.ctx;
                        let chartArea = {
                            top: chart.chartArea.top,
                            bottom: chart.chartArea.bottom,
                            left: chart.chartArea.left,
                            right: chart.chartArea.right
                        }
                        const {
                            x,
                            y,
                            draw
                        } = chart.corsair;

                        if (!draw) {
                            return;
                        }

                        ctx.lineWidth = opts.width || 0;
                        ctx.setLineDash(opts.dash || []);
                        ctx.strokeStyle = opts.color || 'black'

                        ctx.save();
                        ctx.beginPath();
                        ctx.moveTo(x, chartArea.bottom);
                        ctx.lineTo(x, chartArea.top);
                        // ctx.moveTo(left, y);
                        // ctx.lineTo(right, y);
                        ctx.stroke();
                        ctx.restore();

                        ctx.closePath();

                        ctx.beginPath();
                        ctx.moveTo(chartArea.left, chartArea.bottom);
                        let lastX = 0;
                        let lastY = 0;
                        chart.data.datasets[0].data.some((value, i) => {
                            let pointX = chart.scales.x.getPixelForValue(i);
                            let pointY = chart.scales.y.getPixelForValue(value);
                            if(pointX > x){
                                let bistiX = x;
                                let bistiY = 0;
                                let m = (lastY - pointY) / (lastX - pointX);
                                let q = pointY - m * pointX;
                                bistiY = m * x + q;
                                ctx.lineTo(bistiX, bistiY);
                                return true;
                            }
                            ctx.lineTo(pointX, pointY);
                            lastX = pointX;
                            lastY = pointY;
                        })
                        ctx.lineTo(x, chartArea.bottom)
                        ctx.lineTo(chartArea.left, chartArea.bottom);
                        ctx.setLineDash([0,0]);
                        ctx.lineWidth = 0;
                        
                        const gradient = ctx.createLinearGradient(0, 0, x, 0);
                        gradient.addColorStop(0, "#05153E00");
                        gradient.addColorStop(1, "#04154120");
                        
                        ctx.fillStyle = gradient;
                        // Add three color stops
                        ctx.fill();
                        
                    }
                }
            ],
            options: {
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                legend: {
                    display: false
                },scales: {
                x: {
                    display: false,
                    grid: {
                        display: false
                    },
                },
                y: {
                    display: false,
                    grid: {
                        display: false
                    },
                },
            },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        position: 'nearest',
                        enabled: false,
                        external: (context) => {
                            // Tooltip Element
                            let { chart, tooltip } = context;
                            let tooltipEl = getOrCreateTooltip(chart);

                            // Hide if no tooltip
                            if (tooltip.opacity === 0) {
                                tooltipEl.style.opacity = "0";
                                return;
                            }

                            // Set Text
                            if (tooltip.body) {
                                let titleLines = tooltip.title || [];
                                let bodyLines = tooltip.body.map(b => b.lines);
                                
                                var label = titleLines[0].toString();

                                tooltipEl.innerHTML = `
                                    <span class="fs_12 fw_600 blue mb-4 d-block">` + label + `</span>
                                    <span class="fs_12 fw_600 grey mb-2 d-block">Price</span>
                                    <span class="fs_16 fw_500 blue d-block">$` + bodyLines[0] + `</span>
                                `;
                            }

                            let { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

                            // Display, position, and set styles for font
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.left = positionX + tooltip.caretX + 'px';
                            tooltipEl.style.top = positionY + tooltip.caretY + 'px';
                            tooltipEl.style.font = tooltip.options.bodyFont.string;

                        }
                    },
                    corsair: {
                        dash: [2, 2],
                        color: '#041541',
                        width: 1
                    }
                }
            },
        });
        
        function getOrCreateTooltip(chart) {
            let tooltipEl = chart.canvas.parentNode.querySelector('div.tooltip');

            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.classList.add('tooltip');
                tooltipEl.style.padding = '15px 20px';
                tooltipEl.style.borderRadius = '8px';
                tooltipEl.style.opacity = "1";
                tooltipEl.style.pointerEvents = 'none';
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.transform = 'translate(8px, -110px)';
                tooltipEl.style.transition = 'all .1s';
                tooltipEl.style.whiteSpace = 'nowrap';
                tooltipEl.style.background = 'white';
                tooltipEl.style.boxShadow = '0px 0px 10px 0px rgba(4, 21, 65, 0.15)';
                chart.canvas.parentNode.appendChild(tooltipEl);
            }

            return tooltipEl;
        };
    }

}
