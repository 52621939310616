
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class TransactionSuccessModal extends Vue {

    @Prop({
        default: ""
    }) txhash: string;

    @Prop() closeModal: any;

    created() {
    }

    openTransaction() {
        var url = "https://polygonscan.com/tx/" + this.txhash;
        window.open(url, '_blank');
    }

}
