
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import LoadingBar from './loadingBar.vue';
import * as VM from '@/viewModel';

@Options({
    components: {
        LoadingBar
    }
})
export default class TokenWeight extends Vue {

    @Prop({
        default: () => new VM.TokenInFund()
    }) token: VM.TokenInFund;

    created() {
    }

}
