
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { startUrl } from '@/config';
import MetamaskImportToken from './metamaskImportToken.vue';
import Start from './start.vue';
import Buy from './buy.vue';
import Withdraw from './withdraw.vue';

@Options({
    components: {
        MetamaskImportToken,
        Start,
        Buy,
        Withdraw
    }
})
export default class TokenManagement extends Vue {

    @Prop({
        default: 0
    }) price: number;

    @Prop({
        default: 0
    }) lastDayPricePercentageValue: number;
    @Prop({
        default: ""
    }) lastDayPricePercentage: string;

    @Prop({
        default: ""
    }) fundContractAddress: string;

    shareLink: string = "";
    activeSection: string = "start";

    tokenAmount: number = 0;
    withdrawApprovedDone: boolean = false;

    async created() {
        this.shareLink = startUrl + this.$route.path;
        this.$nextTick( () => {
            a2a.init('page')
        })
    }

    changeSection(section: string) {
        this.activeSection = section;
    }
    withdrawPendingSuccess(amount: number) {
        this.tokenAmount = amount;
        this.withdrawApprovedDone = true;
    }

}
